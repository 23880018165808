import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  @Input() title: string;
  @Input() userId: string;

  emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  createForm: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService
  ) {}

  initForm(): void {
    this.createForm = this.fb.group({
      email: new FormControl('',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailRegex)])),
    });
  }

  changeEmail(): void {
    this.userService.changeEmail(this.userId, this.createForm.controls['email'].value).subscribe((response) => {
      this.toastr.success('User email changed', 'Success');
      this.bsModalRef.hide();
    },
    () => {
      this.toastr.error('Something went wrong', 'Failure');
    })
  }

  createUser(): void {
    this.userService.addNewUser(this.createForm.getRawValue()).subscribe(
      (response) => {
        this.toastr.success('User added', 'Success');
        this.bsModalRef.hide();
      },
      () => {
        this.toastr.error('Something went wrong', 'Failure');
      }
    );
  }

  ngOnInit(): void {
    this.initForm();
  }
}
