import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { runtimeEnvironment } from 'src/environments/environment.docker';
import { ContainerInterface } from '../../../models/container.interface';
import { CountryInterface } from '../../../models/country.interface';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ContainerService } from 'src/app/services/container.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UtilsService } from 'src/app/services/utils.service';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './container-modal.component.html',
  styleUrls: ['./container-modal.component.scss'],
})
export class ContainerModalComponent implements OnInit, OnDestroy {
  @Input() containerNumber: any;
  @Output() closed: EventEmitter<never> = new EventEmitter<never>();
  @Input() containerContent: ContainerInterface[] = [];
  isValidIsoContainerNumber = false;
  isContainerAlreadyCreated = false;
  containerApiResponse: number | undefined;
  container: ContainerInterface | undefined;
  countries: CountryInterface[] = [
    {
      name: 'Select',
      code: 'NONE',
      official_name: undefined,
    },
  ];

  destroy$ = new Subject();

  createForm: FormGroup = new FormGroup({
    containerNumber: new FormControl('', Validators.required),
    containerType: new FormControl('', Validators.required),
    containerLength: new FormControl('', Validators.required),
    containerHeight: new FormControl('', Validators.required),
    tareWeightKg: new FormControl('', Validators.required),
    maxPayloadKg: new FormControl('', Validators.required),
    cubicCapcityM3: new FormControl('', Validators.required),
    dataSource: new FormControl('', Validators.required),
    ownerCode: new FormControl('', Validators.required),
    ownerCompany: new FormControl('', Validators.required),
    ownerCity: new FormControl('', Validators.required),
    ownerCountry: new FormControl('', Validators.required),
  });

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    public route: ActivatedRoute,
    public bsModalRef: BsModalRef,
    public containerService: ContainerService,
    private utilsService: UtilsService,
  ) {
  }

  ngOnDestroy(): void {
      this.destroy$.next();
  }

  ngOnInit(): void {

    this.createForm
    .get('containerNumber')
    ?.valueChanges.pipe(distinctUntilChanged(),takeUntil(this.destroy$))
    .subscribe((response) => this.findContainer())

    if (this.containerNumber) {
      this.containerService.getContainer(this.containerNumber).subscribe(
        (response) => {
          this.fillForm(response);
          this.getCountries().then(
            () => {
              this.createForm.controls['ownerCountry'].patchValue(
                response?.owner?.country?.code
              );
            },
            () => {}
          );
        },
        () => {}
      );
    } else {
      this.getCountries();
      this.createForm.controls['ownerCountry'].patchValue('NONE');
    }
  }

  formatText(): void {
    let x = this.createForm
      .get('containerNumber')
      ?.value?.replace(/[^a-zA-Z0-9 ]/g, '')
      .split(' ')
      .join('');
    this.createForm.get('containerNumber')?.setValue(x, { emitEvent: false });
  }

  findContainer() {
    this.isContainerAlreadyCreated = false;
    let containerNumber = this.createForm
      .get('containerNumber')
      ?.value?.replace(/[^a-zA-Z0-9 ]/g, '')
      .split(' ')
      .join('');

      this.isValidIsoContainerNumber = this.utilsService.checkContainerNumber(containerNumber);

      this.containerService
        .getContainer(containerNumber)
        .pipe(distinctUntilChanged())
        .subscribe(
          (data) => {
            this.container = data as unknown as ContainerInterface;
            this.setContainerValues();
            this.containerApiResponse = undefined;
          },
          (err: any) => {
            console.log('no data err')
          }
        );
  }

  private setContainerValues() {
    this.createForm.controls['containerLength'].setValue(this.container?.length_feet ?? null);
    this.createForm.controls['dataSource'].setValue(this.container?.data_source ?? null);
    this.createForm.controls['containerHeight'].setValue(this.container?.height_feet ?? null);
    this.createForm.controls['maxPayloadKg'].setValue(this.container?.max_payload_kg ?? null);
    this.createForm.controls['cubicCapcityM3'].setValue(this.container?.cubic_capcity_m3 ?? null);
    this.createForm.controls['containerType'].setValue(this.container?.type ?? null);
    this.createForm.controls['tareWeightKg'].setValue(this.container?.tare_weight_kg ?? null);
    this.createForm.controls['ownerCode'].setValue(this.container?.owner?.code ?? null);
    this.createForm.controls['ownerCompany'].setValue(this.container?.owner?.company ?? null);
    this.createForm.controls['ownerCity'].setValue(this.container?.owner?.city ?? null);
    this.createForm.controls['ownerCountry'].setValue(this.container?.owner?.country ?? null);
  }

  async getCountries(): Promise<boolean> {

    return new Promise((resolve, reject) => {
      let url = runtimeEnvironment.geodataUrl + '/v1/admin/countries';

      this.http.get(url).subscribe(
        (data) => {
          let countries = data as CountryInterface[];
          this.countries = this.countries.concat(countries);
          resolve(true);

        },
        () => {
          reject();
        }
      );
    });
  }

  fillForm(response: ContainerInterface) {
    this.createForm.controls['containerNumber'].patchValue(
      response?.container_number
    );
    this.createForm.controls['containerType'].patchValue(response.type);
    this.createForm.controls['containerLength'].patchValue(
      response.length_feet
    );
    this.createForm.controls['containerHeight'].patchValue(
      response.height_feet
    );
    this.createForm.controls['tareWeightKg'].patchValue(
      response?.tare_weight_kg
    );
    this.createForm.controls['maxPayloadKg'].patchValue(
      response?.max_payload_kg
    );
    this.createForm.controls['cubicCapcityM3'].patchValue(
      response?.cubic_capcity_m3
    );
    this.createForm.controls['dataSource'].patchValue(
      response?.data_source
    );
    this.createForm.controls['ownerCode'].patchValue(
      response?.owner.code
    );
    this.createForm.controls['ownerCompany'].patchValue(
      response?.owner.company
    );
    this.createForm.controls['ownerCity'].patchValue(
      response?.owner.city
    );
  }

  createContainer() {
    let payload: ContainerInterface = {
      container_number: this.createForm.get('containerNumber')?.value,
      type: this.createForm.get('containerType')?.value,
      length_feet: this.createForm.get('containerLength')?.value,
      height_feet: this.createForm.get('containerHeight')?.value,
      tare_weight_kg: this.createForm.get('tareWeightKg')?.value,
      max_payload_kg: this.createForm.get('maxPayloadKg')?.value,
      data_source: this.createForm.get('dataSource')?.value,
      cubic_capcity_m3: this.createForm.get('cubicCapcityM3')?.value,
      owner: {
        code: this.createForm.get('ownerCode')?.value,
        company: this.createForm.get('ownerCompany')?.value,
        city: this.createForm.get('ownerCity')?.value,
        country: {
          code: this.createForm.get('ownerCountry')?.value,
        },
      },
    };
    console.log('payload',payload)
    if (this.containerContent.filter(x => x.container_number === this.createForm.get('containerNumber')?.value).length > 0) {
      console.log('containerContent',this.containerContent)
      this.isContainerAlreadyCreated = true;
      return;
    }
    else {
      this.isContainerAlreadyCreated = false;
    }


    this.containerService.createContainer(this.createForm.get('containerNumber')?.value, payload).subscribe(
      (resp) => {
        if (this.containerNumber) {
          this.toastr.success('Container has been updated', 'Success');
        } else {
          this.toastr.success('Container has been created', 'Success');
        }
        this.closed.emit();
      },
      () => {

        if (this.containerNumber) {
          this.toastr.error('Container has not been updated', 'Failure');
        } else {
          this.toastr.error('Container has not been created', 'Failure');
        }
      }
    );
  }
}
